import App from '../app';

$(() => {
  /**
   * App.Models.Address class, extends from App.Model
   * @param {Object} the attributes of the Address
   */
  App.Models.Address = App.Model.extend({
    urlRoot: '/api/v1/addresses/',

    url: function() {
      var origUrl = Backbone.Model.prototype.url.call(this);
      return origUrl + (origUrl.charAt(origUrl.length - 1) == '/' ? '' : '/');
    },

    validation: {
      commune: {
        required: true
      },
      street_name: {
        required: true
      },
      address_number: {
        required: true
      },
      latitude: {
        required: true
      },
      longitude: {
        required: true
      }
    },

    onInvalid: function(errors) {
      var error;
      var $inputDiv;

    },

    deactive: function(opts) {
      var _this = this;
      var url = _this.url() + 'deactive/';
      var options = {
        url: url,
        type: 'POST'
      };
      options = _.extend(options, opts);
      return (this.sync || Backbone.sync).call(this, null, this, options);
    },

    setDefault: function(opts) {
      var _this = this;
      var url = _this.url() + 'set_default/';
      var options = {
        url: url,
        type: 'POST'
      };
      options = _.extend(options, opts);
      return (this.sync || Backbone.sync).call(this, null, this, options);
    }
  });

  /**
   * App.AddressCollection class, extends from App.Collection
   * @param {Array} an array data for Address
   */
  App.Collections.AddressCollection = App.Collection.extend({
    url: '/api/v1/addresses/collection',
    model: App.Models.Address
  });

});

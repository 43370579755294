const App = {
  Models: {},
  Collections: {}
};

App.utils = {
  hideLoading() {
    $('body').removeClass('wait');
  },

  thousandSeparator(x) {
    return Math.round(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  },

  showLoading($element) {
    $('body').addClass('wait');

    if ($element && $element.find('.page-loading-icon').length === 0) {
      $element.append(
        '<i class="fa fa-spinner fa-spin page-loading-icon"></i>'
      );
    }
  },

  highlight($element) {
    $element.addClass('highlight');

    setTimeout(() => {
      $element.toggleClass('dim highlight');
    }, 15);

    setTimeout(() => $element.removeClass('dim'), 1010);
  }
};

// add the csrf token to backbone request
var oldSync = Backbone.sync;
Backbone.sync = function(method, model, options) {
  options.beforeSend = function(xhr) {
    xhr.setRequestHeader('X-CSRFToken', csrfmiddlewaretoken);
  };
  return oldSync(method, model, options);
};

App.Model = Backbone.Model.extend({
  /**
  * App.Model.initialize - Listens to the "invalid" event
  */
  initialize: function() {
    var self = this;
    Backbone.Model.prototype.initialize.apply(this, arguments);

    this.on('invalid', function(model, error) {
      self.onInvalid(error);
    });
  },

  /**
  * App.Model.validate - Generic model validation, uses the fields attribute
  * of the object
  */
  validate: function(attrs, options) {
    var fieldName;
    var field;
    var value;

    if (!this.fields) {
      return;
    }

    for (fieldName in this.fields) {
      field = this.fields[fieldName];
      value = attrs[fieldName];

      if (field.maxLength && value.length > field.maxLength) {
        // TODO translate this
        return 'El campo ' + field.name +
          ' debe tener máximo "' + field.maxLength + '" caracteres';
      }

      if (field.minLength && value.length < field.minLength) {
        // TODO translate this
        return 'El campo ' + field.name +
          ' debe tener mínimo "' + field.minLength + '" caracteres';
      }

      if (field.type == 'rut') {
        if (!app.utils.validateRut(value)) {
          return 'El campo ' + field.name +
            ' no es un RUT Chileno válido.';
        }
      }

      if (field.name == 'first_name') {
        if (value == '') {
          return 'El campo nombre ' +
            'es obligatorio';
        }
      }
      if (field.name == 'last_name') {
        if (value == '') {
          return 'El campo apellido paterno ' +
            'es obligatorio';
        }
      }
      if (field.name == 'mother_maiden_name') {
        if (value == '') {
          return 'El campo apellido materno ' +
            'es obligatorio';
        }
      }
    }
  },

  /**
  * App.Model.onInvalid - Generic model error handling, shows the error with
  * bootbox
  */
  onInvalid: function(errors) {
    bootbox.alert(errors);
  }

});

App.Collection = Backbone.Collection.extend({
  parse: function(response) {
    this.recentMeta = response.meta || {};
    return response.objects || response;
  },

  /**
  * Sets the index value of each input and posts it to the server
  */
  saveIndexes: function() {
    var i;
    var aux;
    var triggered = false;

    for (i = 0; i < this.models.length; i += 1) {
      aux = this.models[i];

      if (aux.attributes.index != i) {
        // set without triggering changes
        aux.set({index: i}, {silent: true});

        // save and make it silent if it's already triggered
        aux.save({}, {silent: triggered});
        triggered = true;
      }
    }
  }
});

/**
  * Transforms inputs of a form to json ready objects
  */
$.fn.serializeObject = function() {
  var o = {};
  var a = this.serializeArray();
  $.each(a, function() {
    if (o[this.name] !== undefined) {
      if (!o[this.name].push) {
        o[this.name] = [o[this.name]];
      }
      o[this.name].push(this.value || '');
    } else {
      o[this.name] = this.value || '';
    }
  });
  return o;
};

export default App;

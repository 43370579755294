import Swal from 'sweetalert2'

(function() {
  'use strict';

  var codeInput = '#appendInputButton';
  var couponButton = '.coupon-btn';
  var totalPriceAmount = '#total-price';
  var totalSummaryAmount = '#total-summary';
  var dctoSection = '.dcto-section';
  var dctoNumber = '.dcto-number';
  const webpayPayment = '#webpay-payment';
  const transferPayment = '#transfer-payment';
  const webpayPaymentOption = '#payment-3';
  const transferPaymentOption = '#payment-2';

  $(webpayPaymentOption).click(function(e) {
    $(transferPayment).hide();
    $(webpayPayment).show();
  });

  $(transferPaymentOption).click(function(e) {
    $(transferPayment).show();
    $(webpayPayment).hide();
  });

  $(webpayPayment).click(function(e) {
    e.preventDefault();
    var addressId;
    var payMethodId;

    $.each($('.addressRadios'), function(index, address) {
      if (address.checked == true) {
        addressId = address.value;
      }
    });

    $.each($('.payRadios'), function(index, method) {
      if (method.checked == true) {
        payMethodId = method.value;
      }
    });

    var form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', '/orders/payment/');

    var address = document.createElement('input');
    address.setAttribute('type', 'text');
    address.setAttribute('name', 'address');
    address.setAttribute('value', addressId);

    let observation = document.createElement('input');
    let observationInput = $('#observation').val();
    if (observationInput === undefined) {
      observationInput = '';
    }
    observation.setAttribute('type', 'text');
    observation.setAttribute('name', 'observation');
    observation.setAttribute('value', observationInput);

    var payMethod = document.createElement('input');
    payMethod.setAttribute('type', 'text');
    payMethod.setAttribute('name', 'paymethod');
    payMethod.setAttribute('value', payMethodId);

    var csrf = document.createElement('input');
    csrf.setAttribute('type', 'text');
    csrf.setAttribute('name', 'csrfmiddlewaretoken');
    csrf.setAttribute('value', csrfmiddlewaretoken);

    form.appendChild(address);
    form.appendChild(observation);
    form.appendChild(csrf);
    form.appendChild(payMethod);

    document.body.appendChild(form);

    // validation
    if (addressId == undefined) {
      Swal.fire({
        title: 'Debes agregar una dirección',
        type: 'info',
        confirmButtonText: 'Aceptar',
        confirmButtonClass: 'green-jungle'
      });
    } else {
      form.submit();
    }
  });


  $(transferPayment).click(function(e) {
    e.preventDefault();
    var addressId;
    var payMethodId;
    $(transferPayment).addClass('disabled').prop('disabled', true);
    $(transferPayment).button('loading');

    $.each($('.addressRadios'), function(index, address) {
      if (address.checked == true) {
        addressId = address.value;
      }
    });

    $.each($('.payRadios'), function(index, method) {
      if (method.checked == true) {
        payMethodId = method.value;
      }
    });

    var form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', '/orders/cart/purchase/');

    var address = document.createElement('input');
    address.setAttribute('type', 'text');
    address.setAttribute('name', 'address');
    address.setAttribute('value', addressId);

    var observation = document.createElement('input');
    observation.setAttribute('type', 'text');
    observation.setAttribute('name', 'observation');
    observation.setAttribute('value', $('#observation').val());

    var payMethod = document.createElement('input');
    payMethod.setAttribute('type', 'text');
    payMethod.setAttribute('name', 'paymethod');
    payMethod.setAttribute('value', payMethodId);

    var csrf = document.createElement('input');
    csrf.setAttribute('type', 'text');
    csrf.setAttribute('name', 'csrfmiddlewaretoken');
    csrf.setAttribute('value', csrfmiddlewaretoken);

    form.appendChild(address);
    form.appendChild(observation);
    form.appendChild(csrf);
    form.appendChild(payMethod);

    document.body.appendChild(form);

    // validation
    if (addressId == undefined) {
      Swal.fire({
        title: 'Debes agregar una dirección',
        type: 'info',
        confirmButtonText: 'Aceptar',
        confirmButtonClass: 'green-jungle'
      });
      $(transferPayment).button('reset');
      $(transferPayment).removeClass('disabled').prop('disabled', false);
    } else {
      form.submit();
    }
  });

  /**
  * validate coupon
  * @param {Object} e
  */
  $(couponButton).click(function(e) {

    var data = {
      userId: app.userId,
      code: $(codeInput).val(),
      csrfmiddlewaretoken: csrfmiddlewaretoken
    };

    $.ajax({
      url: '/orders/coupons/validate/',
      type: 'GET',
      data: data,
      success: function(response) {
        var data = response;
        var total;
        if (data.status == '200') {
          var promotion = JSON.parse(data.promotion);
          if (promotion.price_discount == 0) {
            var percentage = parseInt(app.total) * (promotion.percentage_discount/100);
            total = (parseInt(app.total) - percentage) + parseInt(app.shippingCost);
            $(dctoSection).show();
            $(dctoNumber).html(numeral(percentage).format('$0,0[.]0000') + ' (' + promotion.percentage_discount + '%)');
          } else {
            total = (
              parseInt(app.total) - promotion.price_discount) + parseInt(app.shippingCost);
            $(dctoSection).show();
            $(dctoNumber).html(numeral(promotion.price_discount).format('$0,0[.]0000'));
          }

          $(totalPriceAmount).text(numeral(total).format('$0,0[.]0000'));
          $(totalSummaryAmount).text(numeral(total).format('$0,0[.]0000'));
          app.codeInput = $(codeInput).val();
        } else {
          swal(
            'Cupón no encontrado',
            'Ya ha sido ocupado o no existe',
            'error'
          );
        }

        $(codeInput).val('');
      }
    });
  });

}());

const loadGoogleMapsApi = require('load-google-maps-api');

class Map {
  static loadGoogleMapsApi() {
    return loadGoogleMapsApi({
      key: 'AIzaSyCsSvFz-2WxMAftHv1YudulLYeAZCsjxQA',
      libraries: ['places']
    });
  }
  static createMap(googleMaps, mapElement, options) {
    return new googleMaps.Map(mapElement, options);
  }
}
export { Map };

import App from '../app';
import { Map } from './map';
import '../models/address';

$(() => {
  const addressTimes = '#address-times';
  const addressSearch = '#address-search-icon';
  const addressSaveButton = '#address-save-button';
  const addressUpdateButton = '#address-update-button';
  const autocompleteInput = '#address-autocomplete';
  const apartmentNumberId = '#id_apartment-number';
  const addressMoreInfoId = '#id_extra-info';
  const sidebarId = '.page-sidebar';
  const logoId = '#logo';

	var appaddressInputs;
  var map;
  var address;
  var markers = [];

  function createMap(div, input) {
    var mapOptions = {
      zoom: 8,
      center: {lat: -33.447487, lng: -70.673676},
      mapTypeControl: false
    };
    Map.loadGoogleMapsApi().then(function(googleMaps) {
      let map = Map.createMap(
        googleMaps,
        document.getElementById(div),
        mapOptions
      );
      let autocomplete = new googleMaps.places.Autocomplete(input);
      autocomplete.addListener('place_changed', function() {
        appaddressInputs = fillInAddress(this, map);
      });
      google.maps.event.addListenerOnce(map, 'tilesloaded', function(){
        if (typeof addressId !== 'undefined') {
          $(addressSearch).click();
        }
      });
    });
  }

  function clearForm() {
    $(autocompleteInput).val('');
    $(apartmentNumberId).val('');
    $(addressMoreInfoId).val('');
    // clear markers
    if (markers.length > 0) {
      markers[0].setMap(null);
      markers.pop();
    }
  }

  function geocodePosition(pos, div) {
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({
      latLng: pos
    },
    function(results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        $(div).val(results[0].formatted_address);
        var addressInputs = {};

        for (var i = 0; i < results[0].address_components.length; i++) {
          var component = results[0].address_components[i];

          if (component.types[0] == 'administrative_area_level_3') {
            addressInputs.commune = component.long_name;
          }
          if (component.types[0] == 'street_number') {
            addressInputs.address_number = component.long_name;
          }
          if (component.types[0] == 'route') {
            addressInputs.street_name = component.long_name;
          }
          if (component.types[0] == 'locality') {
            addressInputs.locality = component.long_name;
          }
        }
        if (addressInputs.address_number == undefined) {
          addressInputs.address_number = 's/n';
        }
        if (addressInputs.street_name == undefined) {
          addressInputs.street_name = 's/c';
        }

        addressInputs.latitude = results[0].geometry.location.lat();
        addressInputs.longitude = results[0].geometry.location.lng();

        appaddressInputs = addressInputs;

        $("#mapErrorMsg").hide(100);
      } else {
        $("#mapErrorMsg").html(gettext('Cannot determine address at this location.') + status).show(100);
      }
    });
  }

  function codeAddress(stringAddress, map) {
    var geocoder = new google.maps.Geocoder();
    var address;

    if (!stringAddress) {
      return;
    } else {
      address = stringAddress + ' Chile';
    }

    geocoder.geocode({ 'address': address}, function(results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        var latLng = results[0].geometry.location;

        // clear markers
        if (markers.length > 0) {
          markers[0].setMap(null);
          markers.pop();
        }

        var marker = new google.maps.Marker({
          map:map,
          draggable:true,
          animation: google.maps.Animation.DROP,
          position: results[0].geometry.location
        });
        markers.push(marker);
        google.maps.event.addListener(marker, 'dragend', function() {
          geocodePosition(marker.getPosition(), autocompleteInput);
        });
        var bounds = new google.maps.LatLngBounds();
        bounds.extend(marker.getPosition());

        if(bounds != null && map != null){
          map.fitBounds(bounds);
          bounds = null;
        }

        var addressInputs = {};

        for (var i = 0; i < results[0].address_components.length; i++) {
          var component = results[0].address_components[i];

          if (component.types[0] == 'administrative_area_level_3') {
            addressInputs.commune = component.long_name;
          }
          if (component.types[0] == 'street_number') {
            addressInputs.address_number = component.long_name;
          }
          if (component.types[0] == 'route') {
            addressInputs.street_name = component.long_name;
          }
        }

        addressInputs.latitude = latLng.lat();
        addressInputs.longitude = latLng.lng();

        if (addressInputs.street_name == undefined) {
          addressInputs.street_name = '';
        }
        if (addressInputs.address_number == undefined) {
          addressInputs.address_number = '';
        }

        if (addressInputs.street_name == '' || addressInputs.address_number == '') {
          var addressString = address;
        } else {
          var addressString = addressInputs.street_name + ' ' + addressInputs.address_number + ', ' +
            addressInputs.commune;
        }
        $(autocompleteInput).val(addressString);

        appaddressInputs = addressInputs;
        $(addressTimes).show();

      } else {
        swal(
          gettext('Address'),
          gettext('A valid address wasn\'t found.'),
          'error'
        );
        $(addressTimes).hide();

      }
      return addressInputs;
    });
  }

  function fillInAddress(autocomplete, map) {

    // Get the place details from the autocomplete object.
    var place = autocomplete.getPlace();
    var addressInputs = {};
    if (place.geometry != undefined) {

      // clear markers
      if (markers.length > 0) {
        markers[0].setMap(null);
        markers.pop();
      }

      var marker = new google.maps.Marker({
        map:map,
        draggable:true,
        animation: google.maps.Animation.DROP,
        position: place.geometry.location
      });
      markers.push(marker);
      google.maps.event.addListener(marker, 'dragend', function() {
        geocodePosition(marker.getPosition(), autocompleteInput);
      });
      var bounds = new google.maps.LatLngBounds();
      bounds.extend(marker.getPosition());

      if(bounds != null && map != null){
        map.fitBounds(bounds);
        bounds = null;
      }

      for (var i = 0; i < place.address_components.length; i++) {
        var component = place.address_components[i];

        if (component.types[0] == 'administrative_area_level_3') {
          addressInputs.commune = component.long_name;
        }
        if (component.types[0] == 'street_number') {
          addressInputs.address_number = component.long_name;
        }
        if (component.types[0] == 'route') {
          addressInputs.street_name = component.long_name;
        }
        if (component.types[0] == 'locality') {
          addressInputs.locality = component.long_name;
        }
      }
      if (addressInputs.address_number == undefined) {
        addressInputs.address_number = 's/n';
      }
      if (addressInputs.street_name == undefined) {
        addressInputs.street_name = 's/c';
      }

      $(addressTimes).show();

      addressInputs.latitude = place.geometry.location.lat();
      addressInputs.longitude = place.geometry.location.lng();

    } else {
      codeAddress(place.name, map);
    }

    return addressInputs;
  }

  // init create address map
  if (typeof addressMap !== 'undefined') {
    const autocompleteMapInput = document.getElementById('address-autocomplete');
    createMap('address-map', autocompleteMapInput);
  }

  $(addressSearch).click( function (event) {
    var input = document.getElementById('address-autocomplete');
    google.maps.event.trigger(input, 'focus', {});
    google.maps.event.trigger(input, 'keydown', { keyCode: 13 });
  });

  $(addressTimes).click( function (event) {
    if (markers.length > 0) {
      markers[0].setMap(null);
      markers.pop();
    }
    $('#address-autocomplete').val('');
    $(addressTimes).hide();
  });

  function addressSave() {
    if (appaddressInputs) {
      var data = {
        user: {id: userId, first_name: ''},
        commune: {name: appaddressInputs.commune},
        address_number: appaddressInputs.address_number,
        street_name: appaddressInputs.street_name,
        apartment_number: $(apartmentNumberId).val(),
        extra_info: $(addressMoreInfoId).val(),
        longitude: appaddressInputs.longitude.toFixed(10),
        latitude: appaddressInputs.latitude.toFixed(10),
        phone_number: $('#id_phone_number').val()
      };

      let address = new App.Models.Address(data);

      address.save({}, {
        success: function(model, response) {
          window.location.replace("/addresses/");
        },
        error: function(jqXHR, textStatus, errorThrown) {
          var msg;
        }
      });
    }
  }

  function addressUpdate() {
    if (appaddressInputs) {
      var data = {
        user: {id: userId, first_name: ''},
        commune: {name: appaddressInputs.commune},
        address_number: appaddressInputs.address_number,
        street_name: appaddressInputs.street_name,
        apartment_number: $(apartmentNumberId).val(),
        extra_info: $(addressMoreInfoId).val(),
        longitude: appaddressInputs.longitude.toFixed(10),
        latitude: appaddressInputs.latitude.toFixed(10),
        phone_number: $('#id_phone_number').val()
      };

      const addressData = {
        id: addressId
      }

      let address = new App.Models.Address(addressData);

      address.save(data, {
        patch: true,
        success: function(model, response) {
          window.location.replace("/addresses/");
        },
        error: function(jqXHR, textStatus, errorThrown) {
          var msg;
        }
      });
    }
  }

  $(addressSaveButton).click(addressSave);
  $(addressUpdateButton).click(addressUpdate);

});

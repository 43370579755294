import Swal from 'sweetalert2'

(function() {
  'use strict';

  /**
   * Update totals and badges
   * @param {Int} total, {Int}, {Int} quantity
  */
  function updateTotals(total, price, quantity, opt) {
    var $totalDiv = $('.cart-subtotal');

    if (opt == 'add') {

      $totalDiv.html('<span>Subtotal:</span><strong> $' +
        (parseInt(total)) + '</strong>');
      $('#cart-subtotal').data('subtotal', (parseInt(total) +
          parseInt(quantity * price)));
      $('.cart-title').html('Mi Carro <strong>$' + (parseInt(total)) + '</strong>');
    } else {
      $totalDiv.html('<span>Subtotal</span><strong> $' + (total) + '</strong>');
      $('#cart-subtotal').data('subtotal', (total));
      $('.cart-title').html('Mi Carro <strong>$' + (parseInt(total)) + '</strong>');
    }
    // cart view
    $('#total-products').html('$' + total);
    $('#total-price').html('$' + total);
  }

  /**
   * Update mini cart
   * @param {Object} cart
  */
  function updateMiniCart(cart) {
    // Divs
    var $miniCartTableDiv;
    var miniCartDiv = '';
    // Cart
    var item;
    var item_image;

    $miniCartTableDiv = $('.shopping-cart-body');
    for (var i in cart) {
      item = cart[i];
      var product = $.grep(
          products, function(e) { return JSON.parse(e).id == parseInt(item.product_pk); });
      product = JSON.parse(product);

      miniCartDiv += '<li><div class="media"><a' +
      ' href="/products/detail/' + item.product_pk  + '">' +
      '<img src="' + product.image_thumbnail +
      '" alt="img">' +
      '</a><div class="media-body"><a href="products/detail/' + item.product_pk + '">' +
      '<h4>' + product.name  + '</h4></a>' +
      '<h4><span>' +
      item.quantity + ' x ' +
      item.price + '</span></h4></div></div>' +
      '<div class="close-circle js-remove-mini-cart"><a href="" title="Remove" data-quantity="' + item.quantity +
      '" data-product-id="' + item.product_pk + '" data-price="' +
      item.price +
      '"><i class="fa fa-times" data-product-id="' + item.product_pk + '" data-quantity="' + item.quantity +
      '" data-price="' + item.price + '"  ></i>' +
      '</a></div></div>';
    }
    $miniCartTableDiv.html(miniCartDiv);
  }

  /**
   * Add item to cart
   * @param {Object} e
  */
  $('.js-add-cart').click(function(e) {
      var $this = $(e.target);
      e.preventDefault();
      total = parseInt($('#cart-subtotal').data('subtotal'));

      // Data
      var data;
      var url;
      // Cart
      var total;
      var price;
      var quantity;
      var response;
      // Divs
      var $totalDiv;
      var $priceDiv;
      var $itemQuantityDiv;
      var $productMessage;

      $priceDiv = $this.closest('.media-body').find('.regular-price');
      $itemQuantityDiv = $this.closest('.product-box').find('.item-quantity');
      price = parseInt($priceDiv.data('price'));
      $productMessage = $this.closest('.product-box').find('.product-message');
      if ($productMessage.length === 0) {
        $productMessage = $this.closest('.product-right').find('.product-message');
      }
      if ($itemQuantityDiv.length === 0) {
        $itemQuantityDiv = $this.closest('.product-right').find('.item-quantity');
      }
      quantity = 1;
      data = {
          'product_id': $this.data('product-id'),
          'quantity': quantity,
          'csrfmiddlewaretoken': csrfmiddlewaretoken
      };
      url = '/orders/cart/add/';

      $.ajax({
          url: url,
          type: 'POST',
          data: data,
          success: function(response) {
            if (response.success == true) {
              $itemQuantityDiv.closest('h5').removeClass('d-none');
              $itemQuantityDiv.html(parseInt($itemQuantityDiv.text()) + 1);
              $itemQuantityDiv.html(' ' + parseInt($itemQuantityDiv.text()) + ' ');
              $productMessage.removeClass('d-none');
              updateTotals(response.total, response.price, quantity, 'add');
              updateMiniCart(response.cart);
            } else {
              $itemQuantityDiv.closest('h5').html(
                '<strong>Nos hemos quedado sin stock</strong>');
              $itemQuantityDiv.closest('h5').show();
            }
          }
      });
  });
  /**
   * Change item quantity in shopping cart view
   * @param {Object} e
  */
  $('.quantitySniper').change(function(e) {
      var $this = $(e.target);
      // Data
      var data;
      var url;
      // Cart
      var quantity;
      var response;
      // Divs
      var $totalDiv;
      var $priceDiv;

      quantity = parseInt($this.val());
      var totalItemPrice = $this.closest(
        '.cart-item').find('.product-subtotal');

      if (quantity == 0) {
        $this.val(1);
      } else {
        data = {
            'product_id': $this.data('product-id'),
            'quantity': quantity,
            'csrfmiddlewaretoken': csrfmiddlewaretoken
        };
        url = '/orders/cart/ajax-update/';
        $.ajax({
            url: url,
            type: 'POST',
            data: data,
            success: function(response) {
              updateTotals(response.total, response.price, 1, 'add');
              updateMiniCart(response.cart);
              totalItemPrice.html('$' + (response.price * quantity));
            }
        });
      }
  });
  /**
   * Remove item from mini cart
   * @param {Object} e
  */
  $(document).on('click', '.js-remove-mini-cart', function(e) {
      var $this;
      var data;
      var url;
      var price;
      var quantity;
      var total;
      var $item;

      e.preventDefault();
      $this = $(e.target);
      var productId = $this.data('product-id');
      quantity = $this.data().quantity;

      var miniCartItem = $this[0].closest('.media');

      data = {
          'product_id': $this.data('product-id'),
          'csrfmiddlewaretoken': csrfmiddlewaretoken
      };
      url = '/orders/cart/remove/',

      Swal.fire({
        title: '¿Estás seguro?',
        type: 'warning',
        confirmButtonText: 'Borrar',
        confirmButtonClass: 'green-jungle',
        showCancelButton: true
      }).then((result) => {
        if (result.value) {
          $.ajax({
              url: url,
              type: 'POST',
              data: data,
              success: function(response) {
                $(miniCartItem).addClass('d-none');
                updateTotals(
                  response.total, response.price, quantity, 'remove');
                updateMiniCart(response.cart);

                // Hide product message
                for (var i=0;i<$('.product-message').length;i++) {
                  var message = $('.product-message')[i];
                  if ($(message).data('product-id') == productId) {
                    $(message).addClass('d-none');
                  }
                }
                // Reset product quantity
                for (var i=0;i<$('.item-quantity').length;i++) {
                  var itemQuantity = $('.item-quantity')[i];
                  if ($(itemQuantity).data('product-id') == productId) {
                    $(itemQuantity).text('0');
                  }
                }
              }
          });
        }
      }, function(dismiss) {
      });

  });
  /**
   * Remove item from cart
   * @param {Object} e
  */
  $(document).on('click', '.js-remove-cart', function(e) {
      var $this;
      var data;
      var url;
      var price;
      var quantity;
      var total;
      var $item;
      e.preventDefault();

      $this = $(e.target);
      quantity = parseInt(
        $this.closest('.cart-item-body').find('.cart-qty input').val());

      var cartItem = $this[0].closest('.cart-item');
      var $productMessage = $this.closest('.product-content').find('.product-message');

      data = {
          'product_id': $this.data('product-id'),
          'csrfmiddlewaretoken': csrfmiddlewaretoken
      };
      url = '/orders/cart/remove/',

      Swal.fire({
        title: '¿Estás seguro?',
        type: 'warning',
        confirmButtonText: 'Borrar',
        confirmButtonClass: 'green-jungle',
        showCancelButton: true
      }).then((result) => {
        if (result.value) {
          $.ajax({
              url: url,
              type: 'POST',
              data: data,
              success: function(response) {
                window.location.replace("/orders/cart/detail/");
              }
          });
        }
      });
  });
  /**
   * Purchase a cart
   * @param {Object} e
  */
  $('.js-purchase').click(function(e) {
      var $this;
      var data;
      var url;
      $this = $(e.target);
      url = '/orders/cart/purchase/',
      data = {
          'csrfmiddlewaretoken': csrfmiddlewaretoken
      };

      $.ajax({
          url: url,
          type: 'POST',
          data: data,
          success: function(response) {
          }
      });
  });
  /**
   * Changes the quantity selector
   * @param {Object} e
  */
  $('.up-down').on('click', function() {
      var $button = $(this);
      var oldValue = $button.closest('.quantity').find(
        'input.quantity-input').val();
      if ($button.text() == '+') {
          var newVal = parseFloat(oldValue) + 1;
      } else {
          // Don't allow decrementing below zero
          if (oldValue > 0) {
              var newVal = parseFloat(oldValue) - 1;
          } else {
              newVal = 0;
          }
      }

      $button.closest('.quantity').find('input.quantity-input').val(newVal);

  });
  /**
   * Submit quantity form
   * @param {Object} e
  */
  $('.js-update-cart').click(function(e) {
    $('#updateCart').submit();
  });
}());
